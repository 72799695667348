/*
** @name: Meu Clínicas - examesClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de exames
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';
import moment from "moment";

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8082 : null;
const API_SERVICE = utils.isDev() ? null : 'exames-service';
const API_VERSION = 'v2';

class ExamesClient {
    #examesApiRequest;

    constructor() {
        this.#examesApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#examesApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#examesApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#examesApiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    buscarListaExames(pacCodigo, dtInicial, dtFinal, extraAxiosConfig, options) { 
        var queryParams = {};
        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   
        return this.#examesApiRequest.auth.get(`/exames/lista/${pacCodigo}`, queryParams, null, extraAxiosConfig, options);
    }

    buscarResultadoPDF(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#examesApiRequest.auth.get(`/resultado/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    buscarResultadoPNG(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#examesApiRequest.auth.get(`/resultado/${id}/png`, queryParams, null, extraAxiosConfig, options);
    }

    buscarAnexoExamePDF(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#examesApiRequest.auth.get(`/anexo-exame/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    buscarAnexoExamePNG(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#examesApiRequest.auth.get(`/anexo-exame/${id}/png`, queryParams, null, extraAxiosConfig, options);
    }

    buscarSolicitacaoPDF(codSolicitacao, extraAxiosConfig, options) { 
        return this.#examesApiRequest.auth.get(`/solicitacao/pdf/${codSolicitacao}/1`, null, null, extraAxiosConfig, options);
    }

    buscarSolicitacaoPNG(codSolicitacao, extraAxiosConfig, options) { 
        return this.#examesApiRequest.auth.get(`/solicitacao/png/${codSolicitacao}/1`, null, null, extraAxiosConfig, options);
    }

    cancelarCompartilhamentosXeroViewer(pacCodigo, soeSeq, iseSeq, extraAxiosConfig, options) { 
        return this.#examesApiRequest.auth.get(`/estudos/cancelar-compartilhamentos/${pacCodigo}/${soeSeq}/${iseSeq}`, null, null, extraAxiosConfig, options);
    }

    obterTokenCompartilhamentoXeroViewer(pacCodigo, soeSeq, iseSeq, extraAxiosConfig, options) { 
        return this.#examesApiRequest.auth.get(`/estudos/compartilhar/${pacCodigo}/${soeSeq}/${iseSeq}`, null, null, extraAxiosConfig, options);
    }

    obterUrlXeroViewer(pacCodigo, soeSeq, iseSeq, extraAxiosConfig, options) { 
        return this.#examesApiRequest.auth.get(`/estudos/${pacCodigo}/${soeSeq}/${iseSeq}`, null, null, extraAxiosConfig, options);
    }

    obterUrlXeroViewerByToken(token, extraAxiosConfig, options) { 
        return this.#examesApiRequest.noAuth.get(`/public/estudos/${token}`, null, null, extraAxiosConfig, options);
    }
}

const examesClient = new ExamesClient();
export default examesClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};