import React from "react";
import { AppCustomMessage } from "@hcpa-react-components/app-customization";

import AppConfirmationDialog from "../../components/general/appConfirmationDialog/appConfirmationDialog";
import { useAuthContext } from "../../core/authContext";
import { useAppControllerContext } from "../../core/appControllerContext";

import usuarioClient from "../../apiClients/login/usuarioClient";
import { genesysUtils } from "@hcpa-react-components/genesys-utils";


const ExcluirDados = (props) => {
    const { onCancel, onError } = props;
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();

    const _handleExcluirDados = () => {
        const user = authContext.properties.user;
        if(genesysUtils.typeCheck.isFunction(onCancel)) {
            onCancel();
        }
        usuarioClient.removerUsuario(user.userId, user.pacCodigo, {}, {})
            .then(res => {
                appControllerContext.methods.doLogout(true);
            })
            .catch(err => {
                if(genesysUtils.typeCheck.isFunction(onError)) {
                    onError(err);
                }
            });
    }

    return (
        <>  
            <AppConfirmationDialog
                title={"ATENÇÃO"}
                message={<AppCustomMessage messageId="exclusao-dados_confirmar-exclusao" />}
                onConfirm={() => _handleExcluirDados()}
                onCancel={onCancel} 
                confirmText="Confirmar"
                cancelText="Cancela"
                hideCancelButton={false} />
        </>
    );
}

export default ExcluirDados;
