/*
** @name: Meu Clínicas - meusDados
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Módulo para exibir dados do paciente no novo layout (substituindo antiga 'Paciente')
*/
/*
    Modulos extras:
        CARTAO_PACIENTE: opcional
        EDITAR_DADOS: opcional
        ALTERAR_SENHA: opcional
*/

import React, { Component } from 'react';
import { pascalCase } from '@hcpa-react-components/string-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppServiceSettingsByName, getImageThemeContext } from '../../core/appSpecificConfigHandler.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { hideService, hasServicePermission } from '../../components/general/appNavigationControls/appNavigationControls.js';

import ExcluirDados from './exclusaoDados.js';


// Import module styles
import './meusDados.scss'; 


const MeusDados = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <MeusDadosImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class MeusDadosImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exibeConfirmacaoExclusao: false,
            backendErrorMessage: false
        };
    }

    _handleOpenService = (serviceName) => {
        const { appControllerContext } = this.props;
        appControllerContext.methods.doAddCardModule(serviceName, {}, false);
    }

    _handleRemoveError = (err) => {
        this.setState({
            backendErrorMessage: {
                header: "Ops!",
                message: "Ocorreu um erro processando sua solicitação."
            }
        });
    }

    _setConfirmarExclusaoVisibility = (visible) => {
        this.setState({ exibeConfirmacaoExclusao: visible });
    }

    render() {
        const { authContext, appContextConfig } = this.props;
        const { backendErrorMessage, exibeConfirmacaoExclusao } = this.state;
        const user = authContext.properties.user;
        const serviceConfig = getAppServiceSettingsByName(appContextConfig, APP_SERVICE_LIST.MEUS_DADOS);
        const hideCartaoPaciente = serviceConfig && serviceConfig.hideCartaoPaciente;

        return(
            <AppCardModuleBasicWrapper wrapperName="meus-dados">

                { exibeConfirmacaoExclusao && 
                <ExcluirDados 
                    onCancel={() => this._setConfirmarExclusaoVisibility(false)} 
                    onError={this._handleRemoveError}/>
                }

                <div className="user-information-section">
                    <div className="section-box">
                        <div className="section-content">
                            <div>
                                <div className="titulo">Nome completo</div>
                                <div className="descricao">{pascalCase(user.nome)}</div>
                            </div>
                            { user.cpf &&
                            <div>
                                <div className="titulo">CPF</div>
                                <div className="descricao">{user.cpf}</div>
                            </div>
                            }
                            { user.dtNascimento &&
                            <div>
                                <div className="titulo">Data de Nascimento</div>
                                <div className="descricao">{user.dtNascimento}</div>
                            </div>
                            }
                            { user.celular &&
                            <div>
                                <div className="titulo">Telefone Celular</div>
                                <div className="descricao">{user.celular}</div>
                            </div>
                            }
                            { user.email &&
                            <div>
                                <div className="titulo">E-mail</div>
                                <div className="descricao">{user.email}</div>
                            </div>
                            }
                            { user.prontuario &&
                            <div>
                                <div className="titulo">Prontuário</div>
                                <div className="descricao">{utils.formatProntuario(user.prontuario)}</div>
                            </div>
                            }
                            <div>
                                <div className="titulo">Cartão SUS</div>
                                <div className="descricao">{user.numeroCns ? user.numeroCns : "(não localizado)"}</div>
                            </div>
                            { !hideCartaoPaciente && user.pacCodigo && user.numeroCns && 
                            !hideService(user, appContextConfig, APP_SERVICE_LIST.CARTAO_PACIENTE) &&
                            <div className="action-cartao">
                                <div className="logo-cartao-wrapper">
                                    <AppCustomImage imageContextFn={getImageThemeContext}
                                        module="meusDados"
                                        imageId="icon_cartao-paciente"
                                        onClick={() => { this._handleOpenService(APP_SERVICE_LIST.CARTAO_PACIENTE); }} />
                                </div>
                                <div className="legenda">(visualizar)</div>
                            </div>
                            }

                        </div>
                    </div>
                </div>

                { backendErrorMessage &&
                <AppMessageBox 
                    id="msg-backend-error" 
                    className="error"
                    messageData={backendErrorMessage} />
                }

                <div className="main-action">
                    { !hideService(user, appContextConfig, APP_SERVICE_LIST.EDITAR_DADOS) &&
                    <div>
                        <button 
                            id="button-editar-dados"
                            className="app-compact-button"
                            disabled={!hasServicePermission(user, APP_SERVICE_LIST.EDITAR_DADOS)}
                            onClick={() => this._handleOpenService(APP_SERVICE_LIST.EDITAR_DADOS)}>EDITAR DADOS</button>
                    </div>
                    }
                    { !hideService(user, appContextConfig, APP_SERVICE_LIST.ALTERAR_SENHA) &&
                    <div>
                        <button 
                            id="button-alterar-senha"
                            className="app-compact-button" 
                            onClick={() => this._handleOpenService(APP_SERVICE_LIST.ALTERAR_SENHA)}>ALTERAR SENHA</button>
                    </div>
                    }
                    { !hideService(user, appContextConfig, APP_SERVICE_LIST.EXCLUIR_DADOS) &&
                    <div>
                        <button 
                            id="button-excluir-dados"
                            className="app-compact-button" 
                            onClick={() => this._setConfirmarExclusaoVisibility(true)}>EXCLUIR DADOS</button>
                    </div>
                    }
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default MeusDados;