/*
** @name: Meu Clínicas - externalLinkRedirect
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Módulo para redirecionar para um modulo através de um link externo
*/

import React from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import integrationToken from '../../core/integrationToken.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getAppLinkRedirectSettingsPropertyByName } from '../../core/appSpecificConfigHandler.js';
import { getAppServiceIdByConfigName } from '../../core/appServiceList.js';

import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppLoading from '../../components/general/appLoading/appLoading.js';

import InvalidPage from '../invalidPage/invalidPage.js';
import { PARAM_AUTO_OPEN_INTEGRATION } from '../../core/integrationToken.js';


// Import module styles
import './externalLinkRedirect.scss';


const SERVICE_PATH = '/go/';
const REDIRECT_TIMEOUT_ON_SUCCESS = 1000;
const PARAM_HOME_ON_ERROR = "hoe";

const _buildParameterPayload = (paramConfig, params) => {
    if(!genesysUtils.typeCheck.isArray(paramConfig)) {
        return null;
    }
    const parameters = {};
    paramConfig.forEach(pcfg => {
        if(genesysUtils.typeCheck.isObject(pcfg)) {
            Object.keys(pcfg).forEach(key => {
                parameters[key] = _buildParameterPayload(pcfg[key], params);
            });
        } else {
            const value = params.shift();
            parameters[pcfg] = value ? value : null;
        }
    }); 

    return parameters;
}

const _extractPageInfo = (appCfg, currentPath) => { 
    try {
        const re = new RegExp("^" + SERVICE_PATH);
        const pathParam = currentPath.replace(re, '').replace(/\/$/, '');
        const paramList = (genesysUtils.typeCheck.isString(pathParam) && pathParam.trim()) ? pathParam.split('/') : [];

        // Identificar modulo para redirecionamento
        const linkId = paramList.shift();
        const linkList = getAppLinkRedirectSettingsPropertyByName(appCfg, "links");
        const redirectConfig = linkList && linkList[linkId];
        const moduleName = redirectConfig ? redirectConfig.moduleName : null;
        if(getAppServiceIdByConfigName(moduleName)) { // Construir payload de parametros
            const parameters = _buildParameterPayload(redirectConfig.parameters, paramList);
            return { moduleName, parameters };
        }
    } catch(e) {
        // No additional treatment on erros
    }
    return {};
}

const _isShowNotFoundOnError = (appCfg, queryString) => {
    const notFoundOnError = getAppLinkRedirectSettingsPropertyByName(appCfg, "notFoundOnError");
    const sp = new URLSearchParams(queryString);
    const forceHomeOnError = genesysUtils.typeCheck.isString(sp.get(PARAM_HOME_ON_ERROR));
    return notFoundOnError && !forceHomeOnError ? true : false;
}

const _redirectToURL = (url) => {
    window.location.href = url;
}

const _sendToHome = (appControllerContext, token, urlRedirect) => {
    if(urlRedirect) {
        // Prepare to redirect with integration token
        const appLoginUrl = process.env.REACT_APP_LOGIN_ADDRESS;
        const url = `${appLoginUrl}${token ? `?${PARAM_AUTO_OPEN_INTEGRATION}=${token}` : ""}`;
        _redirectToURL(url);
    } else {
        // Send to home without redirect with itegration token
        appControllerContext.methods.doTriggerLoginIntegration(token);
    }
}

const parseExternalLinkRedirect = (appCfg, pathname) => {
    const { moduleName, parameters } = _extractPageInfo(appCfg, pathname);
    const token = integrationToken.generate(moduleName, parameters, null);
    return token;
}

const ExternalLinkRedirect = (props) => {
    const appContextConfig = useAppConfigContext().getContextConfig();
    const appControllerContext = useAppControllerContext();
    const token = parseExternalLinkRedirect(appContextConfig, props.location.pathname);
    const useRedirectOnDesktop = getAppLinkRedirectSettingsPropertyByName(appContextConfig, "useRedirectOnDesktop");
    const useUrlRedirect = useRedirectOnDesktop && genesysUtils.deviceCheck.isDesktop();

    if(!token) {
        if(_isShowNotFoundOnError(appContextConfig, props.location.search)) { 
            return <InvalidPage />;
        } else { // redirect to home without any integration token
            setTimeout(() => _sendToHome(appControllerContext, null, useUrlRedirect), 1);
            return null;
        }
    }

    if(!useUrlRedirect) {
        setTimeout(() => _sendToHome(appControllerContext, token, false), 1);
        return null;
    } else {
        setTimeout(() => _sendToHome(appControllerContext, token, true), REDIRECT_TIMEOUT_ON_SUCCESS);
        return(
            <div className="external-link-redirect-wrapper">
                <AppExtraDocumentHead subTitle="Carrengando..." robots="noindex,nofollow" />

                <div className="content-box">
                    <AppLoading id="externalLinkRedirectLoadingId" config={appContextConfig} visible={true} />
                </div>
            </div>
        );
    }
}

export default ExternalLinkRedirect;

export {
    parseExternalLinkRedirect
}