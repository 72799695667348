import React, { Component } from 'react';
import { Input, Icon, Accordion } from 'semantic-ui-react';

import PasswordControl from './passwordControl.js';


// Import module styles
import './password.scss'; 


class Password extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password: props.value ? props.value : null,
            inputPasswordType: "password",
            showSubtitle: true,
            passwordControl: new PasswordControl()
        } 
    }

    _buildProperties = () => {
        // Create 'input' properties on a separate object to avoid undesired blinks due to possible type change
        const { className } = this.props;
        const inputProps = {};

        inputProps.fluid = true;
        inputProps.id = this.props.id;
        inputProps.name = this.props.name;
        inputProps.disabled = this.props.disabled ? true : false;
        inputProps.onChange = e => this._handleChange(e.target.value);
        inputProps.autoComplete = "off";
        inputProps.className = `${className ? `${className} ` : ""}${this.state.password ? "has-content": "empty"}`;
        inputProps.label = this.props.labelText;
        inputProps.labelPosition = "right";
        inputProps.value = this.state.password ? this.state.password : "";
        inputProps.icon = (
            <Icon name={this.state.inputPasswordType === "password" ? "eye" : "eye slash"} 
                link 
                onClick={this._togglePasswordVisibility}
                title={this.state.inputPasswordType === "password" ? "Exibir" : "Esconder"} 
            />
        );
        if(this.props.disablePaste) {
            inputProps.onPaste = e => e.preventDefault();
            inputProps.onDrop = e => e.preventDefault();
        }

        if(this.state.inputPasswordType==="password") {
            inputProps.type = "password";
        }

        return inputProps;
    }

    _getStrengthInfo = (value) => {
        const useMeter = this.props.strengthMeter || this.props.strengthMeterAlwaysVisible;
        return useMeter ? this.state.passwordControl.checkStrength(value) : null;
    }

    _handleChange = (value) => {
        value = this.state.passwordControl.checkConstraint(value) ? value : this.state.password;
        const strengthInfo = this._getStrengthInfo(value);

        this.setState({ password: value });

        // invocando o callback externo
        const valid = strengthInfo ? (strengthInfo.valid ? true : false) : null;
        this.props.onChange(value, valid, this.props.name);
    }

    _renderStrengthInfo = () => { 
        const className = this.props.darkerInfo ? ` darker-info` : "";
        const strengthInfo = this._getStrengthInfo(this.state.password || " ");
        if (strengthInfo === null) {
            return null;
        }

        return (
            <div className={`__strength-info-wrapper__${className}`}>
                <Accordion className="full-strength-info">
                    <Accordion.Title active={this.state.showSubtitle} onClick={this._toggleSubtitle}>
                        <div className={`strength ${strengthInfo.strength}`}>
                            {this._renderStrengthMeter(strengthInfo)}
                            <span className="strength-text">{strengthInfo.strengthTitle}</span>
                            <Icon name="dropdown" className="ignore-form-style subtitle-control" />
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.showSubtitle}>
                        <div className="strength-info-box">
                            <span className="rules-title">
                                Critérios para preenchimento da senha:
                            </span>
                            <ul>
                                {strengthInfo.policyProcessing.map(policyResult => 
                                    <li className={`${policyResult.matches ? "matched" : "not-matched"} ${policyResult.required ? "required" : "not-required"} `}
                                        key={policyResult.policyId}>
                                        <Icon className="match-result" name={policyResult.matches ? "check circle outline" : "circle outline"} />
                                        <span className="policy-title">{policyResult.title}</span>
                                    </li>    
                                )}
                            </ul>                       
                        </div>
                    </Accordion.Content>                                
                </Accordion>
            </div>
        )
    }

    _renderStrengthMeter = (strengthInfo) => {
        const strengthBars = [];
       
        for (let index = 0; index < strengthInfo.policyProcessing.length; index++) {
            strengthBars.push(
                <span className={`strength-bar ${index < strengthInfo.matchCount ? "filled" : ""}`}
                        key={`bar-${index}`}>
                </span>
            );            
        }

        return (
            <div className="strength-meter">
                {strengthBars}
            </div>
        );
    }

    _togglePasswordVisibility = () => {
        this.setState({ inputPasswordType: this.state.inputPasswordType === "password" ? "input" : "password"});
    } 

    _toggleSubtitle = () => {
        this.setState({ showSubtitle: !this.state.showSubtitle});
    } 

    render() {
        const inputProps = this._buildProperties();
        const renderMeter = this.props.strengthMeterAlwaysVisible || (this.props.strengthMeter && this.state.password);

        return (
            <>
                <Input {...inputProps} />

                { renderMeter && this._renderStrengthInfo() }
            </>
        );
    }
}

export default Password;
