/*
** @name: Meu Clínicas - notificationClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de notificações
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8088 : null;
const API_SERVICE = utils.isDev() ? null : 'notification-service';
const API_VERSION = 'v2';

class NotificationClient {
    #notificationApiRequest;

    constructor() {
        this.#notificationApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#notificationApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#notificationApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#notificationApiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    noticesList(pacCodigo, extraAxiosConfig, options) {
        const queryParams = { pacCodigo };
        return this.#notificationApiRequest.auth.get(`/notices/list/${pacCodigo}`, queryParams, null, extraAxiosConfig, options);
    }

    pushDeviceRegister(requestData, extraAxiosConfig, options) {
        return this.#notificationApiRequest.auth.post('/push/register-device', requestData, null, extraAxiosConfig, options);
    }
}

const notificationClient = new NotificationClient();
export default notificationClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};