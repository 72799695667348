/*
** @name: Meu Clínicas - appNavigationControls
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render dos controles de navegação (Botões e Barra de botões inferior)
**
** @date: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Adição de novas permissões/ocultar
**
** @date: Agosto 2022 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Alterado metodos 'hasServicePermission' e 'hideService' para receber usuário no lugar do contexto de autenticação
*/

import React, { Component } from 'react';
import $ from 'jquery';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import specialAccessManager, { PERMISSIONS } from '../../../core/specialAccessManager.js';
import buildCardParameters from '../../../core/buildCardParameters.js';
import { useAuthContext } from '../../../core/authContext.js';
import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppNavigationSettings, getAppServiceSettingsByName, getImageThemeContext, isAppServiceEnabled } from '../../../core/appSpecificConfigHandler.js';
import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../../core/appServiceList.js';

import { isRespostaConsentimentoVigente } from '../../../modules/termoConsentimento/termoConsentimento.js';


// Import module styles
import './appNavigationControls.scss';

// Import required plugins
require('jquery-mousewheel');

const NAV_SCROLL = {
    LEFT: "left",
    RIGHT: "right"
}


const hasServicePermission = (user, serviceName, extraParams) => {
    if(!genesysUtils.typeCheck.isObject(user)) {
        return false;
    }
    const isIdentifiedPatient = user.pacCodigo ? true : false;
    extraParams = genesysUtils.typeCheck.isObject(extraParams) ? extraParams : {};

    switch(serviceName) {
        case APP_SERVICE_LIST.CONSULTAS:
        case APP_SERVICE_LIST.EXAMES:
        case APP_SERVICE_LIST.RECEITAS:
        case APP_SERVICE_LIST.LAUDOS_ATESTADOS:
        case APP_SERVICE_LIST.AGENDAMENTO_CONSULTAS:
        case APP_SERVICE_LIST.ETIQUETAS_MATERIAIS:
        case APP_SERVICE_LIST.PESQUISA_EXPERIENCIA:
            return isIdentifiedPatient;
        case APP_SERVICE_LIST.TELEATENDIMENTO:
            return isIdentifiedPatient && (specialAccessManager.user.isRegistered(user) || extraParams.teleatendimentoAllowNonRegisteredUsers);
        case APP_SERVICE_LIST.ALTERAR_SENHA:
        case APP_SERVICE_LIST.EDITAR_DADOS:
            return specialAccessManager.user.isRegistered(user);
        case APP_SERVICE_LIST.REQUERIMENTO_DOCUMENTOS_PRONTUARIO:
            return specialAccessManager.user.isComplete(user);
        case APP_SERVICE_LIST.REAGENDAMENTO_CONSULTA_CANCELADA_COVID:
            return specialAccessManager.permissions.isAllowed(user, PERMISSIONS.REAGENDAMENTO_CONSULTA_COVID);
        case APP_SERVICE_LIST.TERMO_CONSENTIMENTO:
            return specialAccessManager.permissions.isAllowed(user, PERMISSIONS.LDPD);
        default:
            return true;
    }
}

const hideService = (user, appContextConfig, serviceName) => {
    if(!genesysUtils.typeCheck.isObject(user)) {
        return false;
    }
    const isIdentifiedPatient = user.pacCodigo ? true : false;

    if(!isAppServiceEnabled(appContextConfig, serviceName)) {
        return true;
    }
    switch(serviceName) {
        case APP_SERVICE_LIST.CADASTRO:
            return specialAccessManager.user.isRegistered(user) || specialAccessManager.user.isUnderRequiredAge();
        case APP_SERVICE_LIST.CARTAO_PACIENTE:
            return (!isIdentifiedPatient || !user.numeroCns);
        case APP_SERVICE_LIST.INFORMAR_LOCALIZADOR:
            return isIdentifiedPatient;
        case APP_SERVICE_LIST.ALTERAR_SENHA:
        case APP_SERVICE_LIST.EDITAR_DADOS:
        case APP_SERVICE_LIST.EXCLUIR_DADOS:
        case APP_SERVICE_LIST.REAGENDAMENTO_CONSULTA_CANCELADA_COVID:
        case APP_SERVICE_LIST.SOLICITACAO_AGENDAMENTO_CONSULTA:
            return !hasServicePermission(user, serviceName);
        case APP_SERVICE_LIST.TERMO_CONSENTIMENTO:
            return !hasServicePermission(user, serviceName) || (isRespostaConsentimentoVigente(user) !== true);
        default:
            return false;
    }
}

const AccordionCollapsible = (props) => {
    const { active, iconVariant } = props;
    return(
        <div id="accordionCollapsibleWrapperId" className={active ? "active" : ""}>
            <div className="collapse">
                <AppCustomImage imageContextFn={getImageThemeContext} 
                    module="appNavigationControls" 
                    imageId={`accordion-icon_collapse${iconVariant ? `-${iconVariant}` : ""}`} />
            </div>
            <div className="expand">
                <AppCustomImage imageContextFn={getImageThemeContext} 
                    module="appNavigationControls" 
                    imageId={`accordion-icon_expand${iconVariant ? `-${iconVariant}` : ""}`} />
            </div>
        </div>
    );
}

const NavigationBar = (props) => {
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <NavigationBarImplem 
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class NavigationBarImplem extends Component {
    constructor(props) {
        super(props);

        const navConfig = getAppNavigationSettings(props.appContextConfig);
        const scrollOffset = navConfig.scrollOffset;
        const scrollOffsetOnMouseWheel = navConfig.scrollOffsetOnMouseWheel;
        if(!genesysUtils.typeCheck.isInteger(scrollOffset)) {
            throw new Error("Required app configuration property 'navigationSettings.scrollOffset' is missing or invalid.");
        }

        this.state = {
            alignCenterWhenScrollUnecessary: navConfig.alignCenterWhenScrollUnecessary,
            scrollOffset,
            scrollOffsetOnMouseWheel: genesysUtils.typeCheck.isInteger(scrollOffsetOnMouseWheel) ? scrollOffsetOnMouseWheel : scrollOffset,
            scrollButtonsHideWhenDisabled: navConfig.scrollButtonsHideWhenDisabled,
            scrollButtonsShowOnMobile: navConfig.scrollButtonsShowOnMobile,
            scrollOnMouseWheel: navConfig.scrollOnMouseWheel
        };
    }

    _getScrollElements = () => {
        const container = $("#navigationBarWrapperId > div > .nav-middle > .nav-services-box")[0];
        const content = $("#navigationButtonsWrapperId")[0];
        const ctrlScrollLeft = $("#navigationBarWrapperId > div > .nav-left")[0];
        const ctrlScrollRight = $("#navigationBarWrapperId > div > .nav-right")[0];

        return { container, content, ctrlScrollLeft, ctrlScrollRight }
    }

    _handleMouseWheelScroll = (evnt) => {
        // evnt.deltaFactor pode ser considerado para definir delocamento
        const { deltaX, deltaY } = evnt;
        const delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;
        const scrollDirection = delta<0 ? NAV_SCROLL.RIGHT : NAV_SCROLL.LEFT;
        const { scrollOffsetOnMouseWheel } = this.state;
        this._handleNavBarScroll(evnt, scrollDirection, scrollOffsetOnMouseWheel);
    }

    _handleNavBarScroll = (evnt, scrollDirection, offset) => {
        evnt.stopPropagation();
        evnt.preventDefault();

        const target = evnt.target;
        if($(target).hasClass("nav-scroll-button") && $(target).parent().hasClass("disabled")) {
            return;
        }

        const { container } = this._getScrollElements();
        const scrollOffset = offset ? offset : this.state.scrollOffset;
        if(container) {
            switch(scrollDirection) {
                case NAV_SCROLL.LEFT:
                    container.scrollTo(container.scrollLeft-scrollOffset, 0);
                    break;
                case NAV_SCROLL.RIGHT:
                    container.scrollTo(container.scrollLeft+scrollOffset, 0);
                    break;
                default:
                    break;
            }
            this._updateScrollButtons();
        }
    }

    _updateScrollButtons = () => {
        const { container, content, ctrlScrollLeft, ctrlScrollRight } = this._getScrollElements();
        if(!container || !content) {
            return;
        }

        const { alignCenterWhenScrollUnecessary, scrollButtonsHideWhenDisabled } = this.state;
        const containerWidth = container.clientWidth;
        const contentWidth = content.offsetWidth;
        const containerScrollLeft = container.scrollLeft;
        const scrollable = contentWidth > containerWidth;
        const mayHideNavButton = scrollButtonsHideWhenDisabled || !scrollable;

        if(scrollable || !alignCenterWhenScrollUnecessary) {
            $(container).removeClass("centered");
        } else {
            $(container).addClass("centered");
        }

        if(!ctrlScrollLeft || !ctrlScrollRight) {
            return;
        }

        if(scrollable && containerScrollLeft > 0) {
            $(ctrlScrollLeft).removeClass("disabled hidden");
            $(container).parent().addClass("scroll-left");
        } else {
            $(ctrlScrollLeft).removeClass(!mayHideNavButton ? "hidden" : "disabled");
            $(ctrlScrollLeft).addClass(mayHideNavButton ? "hidden" : "disabled");
            if(scrollButtonsHideWhenDisabled) {
                $(container).parent().removeClass("scroll-left");
            }
        }
        if(scrollable && Math.round(containerScrollLeft+containerWidth) < contentWidth) {
            $(ctrlScrollRight).removeClass("disabled hidden");
            $(container).parent().addClass("scroll-right");
        } else {
            $(ctrlScrollRight).removeClass(!mayHideNavButton ? "hidden" : "disabled");
            $(ctrlScrollRight).addClass(mayHideNavButton ? "hidden" : "disabled");
            if(scrollButtonsHideWhenDisabled) {
                $(container).parent().removeClass("scroll-right");
            }
        }
    }

    componentDidMount() {
        const { container, content } = this._getScrollElements();
        const { scrollButtonsShowOnMobile, scrollOnMouseWheel } = this.state;

        if(scrollOnMouseWheel && genesysUtils.deviceCheck.isDesktop()) {
            $(container).on('mousewheel', (evnt) => this._handleMouseWheelScroll(evnt));
        }
        if(scrollButtonsShowOnMobile && !genesysUtils.deviceCheck.isDesktop()) {
            $(container).on("scroll", () => { this._updateScrollButtons() });
        }

        this.navigationResizeMonitor = genesysUtils.general.startResizeMonitor(content, this._updateScrollButtons);
    }

    componentWillUnmount() {
        genesysUtils.general.stopResizeMonitor(this.navigationResizeMonitor, this._updateScrollButtons);
    }

    render() {
        const { appContextConfig, className } = this.props;
        const serviceList = this.props.serviceList ? this.props.serviceList : getAppNavigationSettings(appContextConfig).navBarDefaultServiceList;
        const { alignCenterWhenScrollUnecessary, scrollButtonsShowOnMobile } = this.state;
        const enableScrollButtons = genesysUtils.deviceCheck.isDesktop() || scrollButtonsShowOnMobile;
        const centerNavButtons = enableScrollButtons && alignCenterWhenScrollUnecessary;

        if(!genesysUtils.typeCheck.isArray(serviceList)) {
            throw new Error(`Missing or invalid 'serviceList' in module 'appNavigationCrontrols'.`);
        }

        return(
            <div id="navigationBarWrapperId" className={className}>
                <div className="navbar-buttons-wrapper">
                    { enableScrollButtons &&
                    <>
                        <div className="nav-left disabled">
                            <NavigationScrollButton
                                imageId="ctrl-icon_scroll-left"
                                onClick={(evnt) => { this._handleNavBarScroll(evnt, NAV_SCROLL.LEFT) }}
                            />
                        </div>
                        <div className="nav-right disabled">
                            <NavigationScrollButton
                                imageId="ctrl-icon_scroll-right"
                                onClick={(evnt) => { this._handleNavBarScroll(evnt, NAV_SCROLL.RIGHT) }}
                            />
                        </div>
                    </>
                    }

                    <div className={`nav-middle${enableScrollButtons ? " scroll-left scroll-right" : ""}`}>
                        <div className={`nav-services-box${centerNavButtons ? " centered" : ""}`}>
                            <div id="navigationButtonsWrapperId">
                                { serviceList.map((serviceName, indx) => {
                                    return(
                                        <NavigationServiceButton
                                            key={`serviceKey_${indx}`}
                                            serviceName={serviceName}
                                            hidden={false}
                                            useCardStyle={false}
                                        />
                                    );
                                })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

const NavigationScrollButton = (props) => {
    const { imageId, onClick } = props;

    return(
        <div
            className={`nav-scroll-button`}
            onClick={(evnt) => { onClick(evnt) }}
        >
            { imageId &&
            <AppCustomImage imageContextFn={getImageThemeContext} 
                module="appNavigationControls" 
                imageId={imageId} />
            }
        </div>
    )
}

const NavigationServiceButton = (props) => {
    const appControllerContext = useAppControllerContext();
    const authContext = useAuthContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const user = authContext.properties.user;
    const { serviceName, hidden, useAlternativeImage, useCardStyle } = props;
    if(hideService(user, appContextConfig, serviceName)) {
        return null;
    }

    const serviceConfig = getAppServiceSettingsByName(appContextConfig, serviceName);
    if(!serviceConfig) {
        throw new Error(`Invalid service name '${serviceName}' in module 'NavigationServiceButton'.`);
    }

    const disabled = !hasServicePermission(user, serviceName);
    const handleButtonClick = (evnt) => {
        if(disabled) {
            return;
        }
        evnt.stopPropagation(); 
        evnt.preventDefault();
        appControllerContext.methods.doAddCardModule(serviceName, buildCardParameters(authContext, serviceName), false);
    }

    // TODO: Verificar 'serviceConfig' se é botão com popup menu (com 'popupMenu' property) para implem. / Criar propriedade para desabilitar popup


    return(
        <div
            className={`nav-button${disabled ? " disabled" : ""}${hidden ? " hidden" : ""}${useCardStyle ? " card-style" : ""}`}
            title={serviceConfig.buttonLabel}
            onClick={(evnt) => { handleButtonClick(evnt) }}
        >
            { serviceConfig.imageId &&
            <AppCustomImage imageContextFn={getImageThemeContext} 
                module="appNavigationControls" 
                imageId={(useAlternativeImage && serviceConfig.alternativeImageId) ? serviceConfig.alternativeImageId : serviceConfig.imageId}
                disableException={true}
                onErrorReturn={serviceConfig.buttonLabel} />
            }
        </div>

    )
}

export {
    hasServicePermission,
    hideService,
    AccordionCollapsible,
    NavigationBar,
    NavigationServiceButton
};
