/*
** @name: Meu Clínicas - appControllerContext
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para contexto da 'controller' da aplicação. Fornece uma serie de metodos para
** interação dos módulos com 'appController'
**
** @date: Agosto 2021
** @description: Adição de novo método
*/

import React, { useContext } from 'react';

import utils from './utils.js';

const AppControllerContext = React.createContext('AppControllerContext');
const AppControllerContextConsumer = AppControllerContext.Consumer;

const AppControllerContextProvider = (props) => {
    const { onLogout, onAddCardModule, onCardFadeOut, onClearCardModule, onGetCurrentCardModule, onGetHelpContext,
            onSetCardModule, onSetHelp, onHideLoading, onShowLoading, onTriggerLoginIntegration, onTriggerRender, 
            onRemoveCardModules, onResetAppBackAction, onSetCardModuleParameters,
            onSetEventOnBeforeCloseModule, onUsageUpdate, onTokenRefresh, onFromWiFi } = props;
    const module = 'AppControllerContextProvider';
    utils.checkRequiredFunctionProperty(onLogout, 'onLogout', module);
    utils.checkRequiredFunctionProperty(onCardFadeOut, 'onCardFadeOut', module);
    utils.checkRequiredFunctionProperty(onAddCardModule, 'onAddCardModule', module);
    utils.checkRequiredFunctionProperty(onClearCardModule, 'onClearCardModule', module);
    utils.checkRequiredFunctionProperty(onGetCurrentCardModule, 'onGetHelpContext', module);
    utils.checkRequiredFunctionProperty(onGetHelpContext, 'onGetHelpContext', module);
    utils.checkRequiredFunctionProperty(onSetCardModule, 'onSetCardModule', module);
    utils.checkRequiredFunctionProperty(onSetHelp, 'onSetHelp', module);
    utils.checkRequiredFunctionProperty(onHideLoading, 'onHideLoading', module);
    utils.checkRequiredFunctionProperty(onShowLoading, 'onShowLoading', module);
    utils.checkRequiredFunctionProperty(onTriggerLoginIntegration, 'onTriggerLoginIntegration', module);
    utils.checkRequiredFunctionProperty(onTriggerRender, 'onTriggerRender', module);
    utils.checkRequiredFunctionProperty(onRemoveCardModules, 'onRemoveCardModules', module);
    utils.checkRequiredFunctionProperty(onResetAppBackAction, 'onResetAppBackAction', module);
    utils.checkRequiredFunctionProperty(onSetCardModuleParameters, 'onSetCardModuleParameters', module);
    utils.checkRequiredFunctionProperty(onSetEventOnBeforeCloseModule, 'onSetEventOnBeforeCloseModule', module);
    utils.checkRequiredFunctionProperty(onUsageUpdate, 'onUsageUpdate', module);
    utils.checkRequiredFunctionProperty(onTokenRefresh, 'onTokenRefresh', module);
    utils.checkRequiredFunctionProperty(onFromWiFi, 'onFromWiFi', module);

    const context = {
        methods: {
            doLogout: onLogout,
            doCardFadeOut: onCardFadeOut,
            doAddCardModule: onAddCardModule,
            doClearCardModule: onClearCardModule,
            doGetCurrentCardModule: onGetCurrentCardModule,
            doGetHelpContext: onGetHelpContext,
            doSetCardModule: onSetCardModule,
            doSetHelp: onSetHelp,
            doHideLoading: onHideLoading,
            doShowLoading: onShowLoading,
            doTriggerLoginIntegration: onTriggerLoginIntegration,
            doTriggerRender: onTriggerRender,
            doRemoveCardModules: onRemoveCardModules,
            doResetAppBackAction: onResetAppBackAction,
            doSetCardModuleParameters: onSetCardModuleParameters,
            doSetEventOnBeforeCloseModule: onSetEventOnBeforeCloseModule,
            doUsageUpdate: onUsageUpdate,
            doTokenRefresh: onTokenRefresh,
            isFromWiFi: onFromWiFi
        }
    }
    return (
        <AppControllerContext.Provider value={context} >
            {props.children}
        </AppControllerContext.Provider>
    );
}

const useAppControllerContext = () => {
    const state = useContext(AppControllerContext);
    return {
        methods: state.methods
    };
}

export { AppControllerContextConsumer, AppControllerContextProvider, useAppControllerContext };