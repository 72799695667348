import React from 'react';
import { Form } from 'semantic-ui-react';

import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import Password from '../../components/fields/password/password.js';

import ExtraActions from './extraActions.js';
import MainButton from './mainButton.js';
import ManterConectado from './manterConectado.js';
import WelcomeMessage from './welcomeMessage.js';


const StepSenha = (props) => {
    const { appContextConfig, disableEntrar, manterConectado, showManterConectado, errorMessage,
            onManterConectadoToggle, onPasswordChange, onEntrar, onVoltar, onRecuperarSenha, onAjuda } = props;

    return(
        <Form className="login-form senha">
            <WelcomeMessage 
                title="Bem Vindo!" 
                message={<AppCustomMessage messageId="login_welcome-step-password" />}
            />

            <div className="float-label-field senha">
                <Password 
                    id="input-senha" 
                    name="senha"
                    className=""
                    darkerInfo={false}
                    labelText="Informe sua senha"
                    strengthMeter={false}
                    onChange={onPasswordChange}
                />     
            </div>

            <ManterConectado visible={showManterConectado} value={manterConectado} onToggle={onManterConectadoToggle} />

            <MainButton
                id="button-entrar" type="default" wrapperClassName="main-action" className="app-form-button"
                disabled={disableEntrar} onClick={onEntrar} text="Entrar" />

            <ExtraActions 
                appContextConfig={appContextConfig}
                onVoltar={onVoltar} onCadastro={null} onRecuperarSenha={onRecuperarSenha} onAjuda={onAjuda} />

            { errorMessage ?
            <AppMessageBox
                id="msg-login-error"
                className="error login-message-box"
                messageData={errorMessage} />
            :
            <div className="login-message-box"></div>
            }
        </Form>
    );
}

export default StepSenha;