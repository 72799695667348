/*
** @name: Meu Clínicas - dataConfirmation
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar confirmação de dados do cadastro
*/
import React from "react";

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const DataConfirmation = (props) => {
    const { fields, registerError, onConfirmar, onVoltar } = props;
    return (
        <div className="cadastro-confirmacao-dados">

            <div className="info-principal">Confirmação dos Dados</div>

            <div className="info-adicional">Por favor verifique se todos os seus dados estão corretos e clique em confirmar para prosseguir com o processo de cadastramento.</div>

            <div className="info-row">
                <div className="title">Localizador:</div>
                <div className="value">{fields.localizador.value ? fields.localizador.value : "NÃO INFORMADO"}</div>
            </div>

            <div className="info-row">
                <div className="title">Nome completo:</div>
                <div className="value">{fields.nome.value}</div>
            </div>

            <div className="info-row">
                <div className="title">CPF:</div>
                <div className="value">{fields.cpf.value}</div>
            </div>

            <div className="info-row">
                <div className="title">Data de nascimento:</div>
                <div className="value">{fields.dtNascimento.value}</div>
            </div>

            <div className="info-row">
                <div className="title">Telefone celular:</div>
                <div className="value">{fields.numCelular.value}</div>
            </div>

            <div className="info-row">
                <div className="title">E-mail:</div>
                <div className="value">{fields.email.value}</div>
            </div>

            { registerError &&
            <AppMessageBox 
                id="msg-cadastro-error" 
                className="error" 
                messageData={registerError} />
            }

            <div className="main-action">
                <button id="button-confirmar" className="app-form-button" onClick={onConfirmar}>CONFIRMAR</button>
                <button id="button-voltar" className="app-form-button" onClick={onVoltar}>VOLTAR</button>
            </div>

        </div>
    );
}

export default DataConfirmation;
