/*
** @name: Meu Clínicas - appHeader
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render do cabeçalho da aplicação quando há 'card' aberto
*/

import React from 'react';

import { AppCustomImage } from '@hcpa-react-components/app-customization';
import { getImageThemeContext } from '../../../core/appSpecificConfigHandler.js';


// Import module styles
import './appHeader.scss'; 


const AppHeader = (props) => {
    const { showLogoWiFi } = props;
    return (
        <div className="app-header-wrapper">
            { showLogoWiFi &&
            <div className="image-box">
                <AppCustomImage imageContextFn={getImageThemeContext} module="appHeader" imageId="logo_wi-fi" className="app-logo" />
            </div>
            }
            <div className="image-box">
                <AppCustomImage imageContextFn={getImageThemeContext} module="appHeader" imageId="logo_header" className="app-logo" />
            </div>
        </div>
    )
}

export default AppHeader;