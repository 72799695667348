/*
** @name: Meu Clínicas - registrationForm
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar instruções do cadastro
*/
import React, { useEffect, useRef, useState } from "react";
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { EMPTY_HREF } from '../../core/utils.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { CheckboxField, InputField, PasswordField } from '../../components/fields/formsBuilderCustoms/';
import { genesysUtils } from "@hcpa-react-components/genesys-utils";


const LOCALIZADOR_MASK = [/[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
const CPF_MASK = [/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '-', /\d/, /\d/];
const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/];
const PHONE_MASK = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

const LinkAceiteTermos = (props) => {
    return(
        <a href={EMPTY_HREF} id="link-termos" onClick={() => props.onClick()}>Li e aceito os termos de uso e privacidade.</a>
    );
}

const buildFormConfiguration = (config, renderLocalizador) => {
    if(!genesysUtils.typeCheck.isArray(config?.pages)) {
        return null;
    }
    const newCfg = { ...config };
    setConfigFieldProperty(newCfg, ["localizador"], "masked.mask", LOCALIZADOR_MASK);
    setConfigFieldProperty(newCfg, ["cpf"], "masked.mask", CPF_MASK);
    setConfigFieldProperty(newCfg, ["dtNascimento"], "masked.mask", DATE_MASK);
    setConfigFieldProperty(newCfg, ["numCelular"], "masked.mask", PHONE_MASK);
    setConfigFieldProperty(newCfg, ["dtNascimento"], "masked.pipe", autoCorrectedDatePipe);
    setConfigFieldProperty(newCfg, ["localizador"], "render", renderLocalizador);
    return newCfg;
}

const RegistrationForm = (props) => {
    const afterErrorMsgRef = useRef();
    const [ formConfig, setFormConfig ] = useState(null);
    const { actionButtonTitle, baseFormConfig, fields, formError, hideLocalizador, onChange, onShowTermos, onFormSubmit } = props;

    const _handleFieldChange = (fields, name, value, ...extra) => {
        const params = { name, value };
        if(name==='senha') {
            params.passwordValid = extra[0];
        }
        onChange(fields, params);
    }

    const _scrollToMessageDiv = () => {
        if(afterErrorMsgRef.current) {
            afterErrorMsgRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
        }
    }
    
    useEffect(() => {
        if(formError) {
            _scrollToMessageDiv();
        }
    }, [formError]);

    useEffect(() => {
        setFormConfig(buildFormConfiguration(baseFormConfig, !hideLocalizador));
    }, [baseFormConfig, hideLocalizador]);

    return (
        <Form className="cadastro-form">
            { formConfig &&
            <FormBuilder 
                blockFieldUpdate={false}
                disableClearErrorOnFieldChange={false}
                config={formConfig}
                page={0}
                fields={fields}
                className="form-content" 
                onChange={_handleFieldChange}
                overrideFieldRender={{
                    'checkbox': CheckboxField,
                    'input': InputField
                }}
                customComponents={{
                    linkAceiteTermos: () => <LinkAceiteTermos onClick={() => onShowTermos()} />,
                    senha: PasswordField,
                    senhaConfirmar: PasswordField,
                }}
            />
            }

            { formError &&
            <AppMessageBox 
                id="msg-cadastro-error" 
                className="error" 
                messageData={formError} />
            }

            <div ref={afterErrorMsgRef} className="main-action">
                <button id="button-proximo" className="app-form-button" onClick={() => onFormSubmit()}>{actionButtonTitle}</button>
            </div>
        </Form>
    );
}

export default RegistrationForm;
