/*
** @name: Meu Clínicas - usuarioClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de login (usuários)
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiRequestInstance } from '../../core/appRequest.js';

import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class UsuarioClient { 
    #usuarioApiRequest;

    constructor() {
        this.#usuarioApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    atualizarUsuario(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.auth.post('/usuario/atualizar-usuario', userData, null, extraAxiosConfig, options);
    }

    atualizarUsuarioNoAuth(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.noAuth.post('/public/atualizar-usuario', userData, null, extraAxiosConfig, options);
    }

    usuarioCadastrar(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.noAuth.post('/public/criar-usuario', userData, null, extraAxiosConfig, options);
    }

    usuarioValidarDados(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.noAuth.post('/public/validar-dados-usuario', userData, null, extraAxiosConfig, options);
    }

    verificarAtivacaoCadastro(token, extraAxiosConfig, options) {
        const queryParams = { tkn: token };
        return this.#usuarioApiRequest.noAuth.get('/public/usuario/ativacao', queryParams, null, extraAxiosConfig, options);
    }

    reenviarEmailTokenViaLink(token, extraAxiosConfig, options) {
        const queryParams = { tkn: token };
        return this.#usuarioApiRequest.noAuth.get('/public/usuario/reenviar-email-token/via-link', queryParams, null, extraAxiosConfig, options);
    }

    reenviarEmailTokenViaLogin(cpf, extraAxiosConfig, options) {        
        const queryParams = { cpf };
        return this.#usuarioApiRequest.noAuth.get('/public/usuario/reenviar-email-token/via-login', queryParams, null, extraAxiosConfig, options);
    }

    recuperarSenhaPorEmail(cpfEmail, extraAxiosConfig, options) {
        const payload = { cpfEmail };
        return this.#usuarioApiRequest.noAuth.post('/public/usuario/recuperar-senha-por-email', payload, null, extraAxiosConfig, options);
    }
     
    redefinirSenha(novaSenha, token, extraAxiosConfig, options) {
        const payload = { newPassword: novaSenha, token };
        return this.#usuarioApiRequest.noAuth.post('/public/usuario/redefinir-senha', payload, null, extraAxiosConfig, options);
    }

    enviarTokenSmsByNumber(cpf, numCelular, extraAxiosConfig, options) {
        const payload = { cpf, numCelular };
        return this.#usuarioApiRequest.noAuth.post('/public/usuario/sms-by-number', payload, null, extraAxiosConfig, options);
    }

    validarTokenSms(token, tokenId, cpf, numCelular, extraAxiosConfig, options) {
        const payload = { token, tokenId, cpf, numCelular };
        return this.#usuarioApiRequest.noAuth.post('/public/usuario/sms-token-validation', payload, null, extraAxiosConfig, options);
    }

    vincularPaciente(localizador, cpf, extraAxiosConfig, options) {
        const queryParams = { localizador, cpf };
        return this.#usuarioApiRequest.auth.get('/usuario/vincular-paciente', queryParams, null, extraAxiosConfig, options);
    }

    ativarSmsUsuario(cpf, token, extraAxiosConfig, options) {
        const queryParams = { cpf, token };
        return this.#usuarioApiRequest.auth.get('/usuario/ativar-sms-usuario', queryParams, null, extraAxiosConfig, options);
    }

    reenviarSms(cpf, extraAxiosConfig, options) {        
        const queryParams = { cpf };
        return this.#usuarioApiRequest.auth.get('/usuario/reenviar-sms', queryParams, null, extraAxiosConfig, options);
    }

    alterarSenha(senhaAtual, novaSenha, novaSenhaConfirmar, extraAxiosConfig, options) {
        const payload = { senhaAtual, novaSenha, novaSenhaConfirmar };
        return this.#usuarioApiRequest.auth.post('/usuario/alterar-senha', payload, null, extraAxiosConfig, options);
    }

    alterarSenhaObrigatoria(senhaAtual, novaSenha, novaSenhaConfirmar, authHeader, extraAxiosConfig, options) {
        const payload = { senhaAtual, novaSenha, novaSenhaConfirmar };
        return this.#usuarioApiRequest.noAuth.post('/usuario/alterar-senha', payload, authHeader, extraAxiosConfig, options);
    }

    buscarPDFCartaoPaciente(pacCodigo, extraAxiosConfig, options) { 
        return this.#usuarioApiRequest.auth.get(`/usuario/cartao-paciente/${pacCodigo}`, {}, null, extraAxiosConfig, options);
    }

    obterLocalizador(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.auth.post('/usuario/obter-localizador', userData, null, extraAxiosConfig, options);
    }

    vincularUsuarios(userData, extraAxiosConfig, options) {
        return this.#usuarioApiRequest.auth.post('/usuario/vincular-usuarios', userData, null, extraAxiosConfig, options);
    }

    removerUsuario(userId, pacCodigo, extraAxiosConfig, options) {
        const payload = { userId, pacCodigo };
        return this.#usuarioApiRequest.auth.post('/usuario/remover', payload, null, extraAxiosConfig, options);
    }
}

const usuarioClient = new UsuarioClient();
export default usuarioClient;