/*
** @name: Meu Clínicas - registrosMedicosClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de registros médicos
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';
import moment from "moment";

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';


const API_PORT = utils.isDev() ? 8083 : null;
const API_SERVICE = utils.isDev() ? null : 'regmedicos-service';
const API_VERSION = 'v2';

class RegistrosMedicosClient {
    #regMedicosApiRequest;

    constructor() {
        this.#regMedicosApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#regMedicosApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#regMedicosApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#regMedicosApiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    buscarListaEtiquetasMateriais(pacCodigo, dtInicial, dtFinal, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   
        return this.#regMedicosApiRequest.auth.get('/etiquetas-materiais/lista', queryParams, null, extraAxiosConfig, options);
    }

    buscarPDFEtiquetasMateriais(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#regMedicosApiRequest.auth.get(`/etiquetas-materiais/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    buscarPNGEtiquetasMateriais(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#regMedicosApiRequest.auth.get(`/etiquetas-materiais/${id}/png`, queryParams, null, extraAxiosConfig, options);
    }

    buscarListaLaudosAtestados(pacCodigo, dtInicial, dtFinal, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   
        return this.#regMedicosApiRequest.auth.get('/laudos-atestados/lista', queryParams, null, extraAxiosConfig, options);
    }

    buscarLaudosAtestadosPDF(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#regMedicosApiRequest.auth.get(`/laudos-atestados/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    indicarLeituraLaudosAtestados(id, pacCodigo, extraAxiosConfig, options) {
        return this.#regMedicosApiRequest.auth.post(`/laudos-atestados/leitura?id=${id}&pacCodigo=${pacCodigo}`, null, null, extraAxiosConfig, options);
    }

    buscarLaudosLmePNG(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#regMedicosApiRequest.auth.get(`/laudosatestados-lme/${id}/png`, queryParams, null, extraAxiosConfig, options);
    }

    buscarLaudosLmePDF(id, pacCodigo, extraAxiosConfig, options) { 
        const queryParams = { pacCodigo };
        return this.#regMedicosApiRequest.auth.get(`/laudosatestados-lme/${id}/pdf`, queryParams, null, extraAxiosConfig, options);
    }

    /* Servico - Validação */
    buscarPDFLaudosAtestadosValidado(payload, extraAxiosConfig, options) {
        return this.#regMedicosApiRequest.noAuth.post('/public/pdf', payload, null, extraAxiosConfig, options);
    }

    validarLaudosAtestados(payload, extraAxiosConfig, options) {
        return this.#regMedicosApiRequest.noAuth.post('/public/validar', payload, null, extraAxiosConfig, options);
    }
}

const registrosMedicosClient = new RegistrosMedicosClient();
export default registrosMedicosClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};